import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

/*
  Custom validators to use everywhere.
*/

// SINGLE FIELD VALIDATORS
export function phoneValidator(val: any): { [key: string]: any } {
  var phoneRegexp = /^[(]{0,1}[0-9]{3}[)\.\- ]{0,1}[0-9]{3}[\.\- ]{0,1}[0-9]{4}$/;
  if (val && !phoneRegexp.test(val)) {
    return { invalidPhone: true };
  }
  return {};
}

export function postalcodeValidator(control: UntypedFormControl): { [key: string]: any } {
  var postalRegexp = /^[1-9][0-9]{5}$/;
  if (control.value && !postalRegexp.test(control.value)) {
    return { invalidPostalCode: true };
  }
  return {};
}

export function emailValidator(control: UntypedFormControl): { [key: string]: any } {
  var emailRegexp = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
  if (control.value && !emailRegexp.test(control.value)) {
    return { invalidEmail: true };
  }
  return {};
}

export function validateEmail(val: string) {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(val);
}

export function youtubeURLValidator(control: UntypedFormControl): { [key: string]: any } {
  var utubeUrlRegexp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
  if (control.value && !utubeUrlRegexp.test(control.value)) {
    return { invalidYoutubeURL: true };
  }
  return {};
}

//CONTROL GROUP VALIDATORS
export function matchingPasswords(group: UntypedFormGroup) {
  var confirm = group.controls["confirm_new_password"];
  if (group.controls["new_password"].value !== confirm.value) confirm.setErrors({ matchingPasswords: true });
  return null;
}

export function onlyNumbersKeys(event: any) {
  const pattern = /[0-9\+\-\ ]/;
  let inputChar = String.fromCharCode(event.keyCode);
  if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
      return false;
  } else {
      return true;
  }
}

export function onlyAlphabetKeys(event: any) {
  const pattern = /^[A-Z]+$/i;
  let inputChar = String.fromCharCode(event.keyCode);
  if (event.keyCode != 32 && !pattern.test(inputChar)) {
      event.preventDefault();
      return false;
  } else {
      return true;
  }
}

export function onlyNumbersControl(control: UntypedFormControl) {
  control.setValue(control.value.replace(/[^0-9]/g, ""));
}
export function onlyAlphabetsControl(control: UntypedFormControl) {
  control.setValue(control.value.replace(/[^a-zA-Z .']/g, ""));
}
export function validateAddressControl(control: UntypedFormControl) {
  control.setValue(control.value.replace(/[^a-zA-Z0-9 #'.,-/():&+_;]/g, ""));
}

export const validEmailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// validate text with alphabets, numbers along with special chars.. not allowing only special chars
export const validDescriptionPattern = "^(?=.*[a-zA-Z\d].*)[a-zA-Z0-9,.' \d!@#$%&*]{7,}$";

// card expiry validations
export function isValidExpiryDate(expiryDate) {
  let cMonth = parseInt((new Date().getMonth() + 1).toString().slice(-2));
  let cYear = parseInt(new Date().getFullYear().toString().slice(-2));
  let expMonth = expiryDate.slice(0, 2);
  let expYear = expiryDate.slice(-2);
  let expMonth1 = expMonth.replace(/^0+/, "");
  if (expYear < cYear) {
    return false;
  } else if (expYear == cYear) {
    if (expMonth1 >= cMonth) {
      return true;
    } else {
      return false;
    }
  }
  return true;
}

//accepts number starting from 6 to 9 only and are of 10 digits
export function isValidMobileNumber(number: string): boolean {
  return /^[5-9]\d{9}$/.test(number);
}